@media (max-width: 575.98px) { 
    #carouselExampleSlidesOnly {
        display: none !important;
    } 
}
@media (min-width: 576px) and (max-width: 767.98px) { 
             
    #carouselExampleSlidesOnly {
        display: none !important;
    }
    
}