@import "./mixins";
@import "./variables";

.carousel-item {
  h1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 80px;
    color: $secondaryColor;
    font-family: "Gentium Book Plus", serif;
  }
}
